import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Introduce from "./components/Introduce";
import MainContent from "./components/MainContent";

function App() {
  const [languageType, setLanguageType] = useState(1);
  const changeLang = (data) => {
    setLanguageType(Number(data));
  };
  return (
    <div
      className={
        languageType === "0"
          ? "App chinese_land_content landing_app_section"
          : languageType === "1"
          ? "App landing_app_section"
          : "App Japanese_land_content landing_app_section"
      }
    >
      <div className="change_language">
        <div className="selected_language">
          <i className="language_icon"></i>
        </div>
        <div className="select_lang_con">
          <ul className="select_lang_list">
            <li
              className={languageType === "1" ? "active" : ""}
              onClick={() => changeLang("1")}
            >
              EN
            </li>
            <li
              className={languageType === "0" ? "active" : ""}
              onClick={() => changeLang("0")}
            >
              中文
            </li>
            <li
              className={languageType === "2" ? "active" : ""}
              onClick={() => changeLang("2")}
            >
              日本語
            </li>
          </ul>
        </div>
      </div>
      <Banner languageType={languageType} />
      <Introduce languageType={languageType} />
      <MainContent languageType={languageType} />
      <Footer languageType={languageType} />
    </div>
  );
}

export default App;
