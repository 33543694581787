import React from "react";

const TitleContent = ({ stepContent, introduceContent, catImgUrl }) => {
  return (
    <div className="title_container">
      <div className="title_step">{stepContent}</div>
      <p>{introduceContent}</p>
      <img className="title_cat" src={catImgUrl} alt="cat"></img>
    </div>
  );
};

export default React.memo(TitleContent);
