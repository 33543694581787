import React from "react";
import { isMobile } from "react-device-detect";
import footerBottomCat from "../assets/images/footerBottomCat.png";
import footerBottomCatMobile from "../assets/images/footerBottomCatMobile.png";
import footerBottomCats from "../assets/images/footerBottomCats.png";
import footerBottomCatsMobile from "../assets/images/footerBottomCatsMobile.png";

const Footer = ({ languageType }) => {
  return (
    <div className="footer_container">
      <div className="footer_green_gap"></div>
      <div className="footer_bg">
        <div className="footer_dialog">
          <div className="footer_dialog_content">
            {languageType === 0 ? (
              <p>更多详细的信息，请咨询OVO Discord社区！ </p>
            ) : languageType === 1 ? (
              <p>
                For more detailed information, please consult the OVO Discord
                community!
              </p>
            ) : (
              <p>
                詳しい情報は、OVOのDiscordコミュニティで聞いてみてください！
              </p>
            )}
            <div className="footer_dialog_content_discord">
              <p>OVO Discord：</p>
              <a
                href="https://discord.com/invite/ovonft"
                target={"_blank"}
                rel="noreferrer"
              >
                https://discord.com/invite/ovonft
              </a>
            </div>
            {languageType === 0 ? (
              <div
                className="footer_button"
                onClick={() =>
                  window.open(
                    "https://ovobsc.ovo.space/#/collection/mysteryBox"
                  )
                }
              >
                 获取盲盒！
              </div>
            ) : languageType === 1 ? (
              <div
                className="footer_button"
                onClick={() =>
                  window.open(
                    "https://ovobsc.ovo.space/#/collection/mysteryBox"
                  )
                }
              >
                Mystery Box! GO !
              </div>
            ) : (
              <div
                className="footer_button"
                onClick={() =>
                  window.open(
                    "https://ovobsc.ovo.space/#/collection/mysteryBox"
                  )
                }
              >
                ガチャガチャ
              </div>
            )}
          </div>
          <img
            className="footer_bottom_cat"
            src={isMobile ? footerBottomCatMobile : footerBottomCat}
            alt="bottom cat"
          ></img>
        </div>
        <img
          className="footer_bottom_cats"
          src={isMobile ? footerBottomCatsMobile : footerBottomCats}
          alt="bottom cats"
        ></img>
      </div>
      <div className="footer_green_gap"></div>
    </div>
  );
};

export default React.memo(Footer);
