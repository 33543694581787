import React from "react";
import ovoAvatar from "../assets/images/ovoAvatar.png";
import userAvatar from "../assets/images/userAvatar.png";
import mysteryBoxUSDT from "../assets/images/mysteryboxUSDT.png";
import blueCat from "../assets/images/blueCat.png";
import greenCat from "../assets/images/greenCat.png";
import yellowCat from "../assets/images/yellowCat.png";
import purpleCat from "../assets/images/purpleCat.png";
import fusion from "../assets/images/fusion.png";
import pictureBook from "../assets/images/pictureBook.png";
import pictureBook2 from "../assets/images/pictureBook2.png";
import mining from "../assets/images/mining.png";
import mining2 from "../assets/images/mining2.png";
import mining3 from "../assets/images/mining3.png";
import mining4 from "../assets/images/mining4.png";
import nyatheesPuzzle from "../assets/images/nyatheesPuzzle.png";

import ovoAvatarMobile from "../assets/images/ovoAvatarMobile.png";
import userAvatarMobile from "../assets/images/userAvatarMobile.png";
import mysteryBoxUSDTMobile from "../assets/images/mysteryboxUSDTMobile.png";
import blueCatMobile from "../assets/images/blueCatMobile.png";
// import greenCatMobile from "../assets/images/greenCatMobile.png";
// import yellowCatMobile from "../assets/images/yellowCatMobile.png";
// import purpleCatMobile from "../assets/images/purpleCatMobile.png";
import fusionMobile from "../assets/images/fusionMobile.png";
import pictureBookMobile from "../assets/images/pictureBookMobile.png";
import pictureBook2Mobile from "../assets/images/pictureBook2Mobile.png";
import miningMobile from "../assets/images/miningMobile.png";
import mining2Mobile from "../assets/images/mining2Mobile.png";
import mining3Mobile from "../assets/images/mining3Mobile.png";
import mining4Mobile from "../assets/images/mining4Mobile.png";
import nyatheesPuzzleMobile from "../assets/images/nyatheesPuzzleMobile.png";

import TitleContent from "./TitleContent";
import { isMobile } from "react-device-detect";

const MainContent = ({ languageType }) => {
  return (
    <div className="main_container">
      <div className="main_content">
        {languageType === 0 ? (
          <TitleContent
            stepContent={"步骤1"}
            introduceContent={"首先，抽取盲盒来获得芝士猫卡牌！"}
            catImgUrl={isMobile ? blueCatMobile : blueCat}
          />
        ) : languageType === 1 ? (
          <TitleContent
            stepContent={"Step 1"}
            introduceContent={
              "For now, let's try to capture Nyathees by turning the Mystery Box!"
            }
            catImgUrl={isMobile ? blueCatMobile : blueCat}
          />
        ) : (
          <TitleContent
            stepContent={"ステップ １"}
            introduceContent={
              "とりあえず、ガチャガチャを回してにゃっちーずを捕獲してみよう！"
            }
            catImgUrl={isMobile ? blueCatMobile : blueCat}
          />
        )}

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content1">
                  <p>据说有超级豪华奖励！到底有什么奖励？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content1">
                  <p>What's the "Ultimate Bonus Festival" all about?</p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content1">
                  <p>最強ボーナス祭り！ ってなんだよ？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content2">
                      <p>
                        让我们争取总计价值442,000U（约300万RMB)的奖励卡牌。有可能一次抽取就会获得10000U(约7万RMB)的奖励卡牌...
                        这些奖励分散在各个阶段的盲盒抽取里，前期后期都有相当豪华的惊喜奖励。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content2">
                      <p>
                        Let's aim for a total of $442,000 worth of bonus cards.
                        You might get a $10,000  bonus card in a single pull...
                        Of course, it's designed to be in the latter half of the
                        Gacha Gacha, but the first half is also quite
                        impressive.
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content2">
                      <p>
                        総額442000USDT(6500万円)分のボーナスNFTを狙いましょう。1ッ発で10000USDT(140万円)ボーナスNFTがドロップするかも....勿論、ガチャガチャの後半部分に仕組まれているけど、前半部分もかなりやばいですよ。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content2">
                      <p>
                        让我们争取总计价值442,000U（约300万RMB)的奖励卡牌。有可能一次抽取就会获得10000U(约7万RMB)的奖励卡牌...
                        这些奖励分散在各个阶段的盲盒抽取里，前期后期都有相当豪华的惊喜奖励。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content2">
                      <p>
                        Let's aim for a total of $442,000 worth of bonus cards.
                        You might get a $10,000  bonus card in a single pull...
                        Of course, it's designed to be in the latter half of the
                        Gacha Gacha, but the first half is also quite
                        impressive.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content2">
                      <p>
                        総額442000USDT(6500万円)分のボーナスNFTを狙いましょう。1ッ発で10000USDT(140万円)ボーナスNFTがドロップするかも....勿論、ガチャガチャの後半部分に仕組まれているけど、前半部分もかなりやばいですよ。
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? mysteryBoxUSDTMobile : mysteryBoxUSDT}
          alt="mystery box usdt"
          className="content_img"
        />
        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          {languageType === 0 ? (
            <div className="main_dialog_content_container_left">
              <p>U君</p>
              <div className="main_dialog_content3">
                <p>没抽中？　有人先拿到了奖励卡诶！</p>
              </div>
            </div>
          ) : languageType === 1 ? (
            <div className="main_dialog_content_container_left">
              <p>U</p>
              <div className="main_dialog_content3">
                <p>Lose？Someone else got the bonus card first!</p>
              </div>
            </div>
          ) : (
            <div className="main_dialog_content_container_left">
              <p>U君</p>
              <div className="main_dialog_content3">
                <p>
                  ハズレた？　ボーナスNFTが誰かに先を取られた！！（ガッカリ）
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content4">
                      <p>
                        别担心，让我们将剩余的卡牌合成超级稀有卡吧。
                        合成出来的卡牌可以挖出大量的 OVO代币。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content4">
                      <p>
                        No worries at all, haha! Let's fuse all the surplus
                        cards and go for super-rare cards. By doing that, we can
                        mine lots of $OVO.Although gutting the token pool... It
                        might cost me…
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content4">
                      <p>
                        そんなことないですよ（笑）　余ったにゃっちーずNFTを合成しまくって、超レアにゃっちーずNFTを目指しましょう。それをもって$OVOトークンを大量に掘りまくって、トークンのプールを空っぽにしてください！...って、僕が損するかも...
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content4">
                      <p>
                        别担心，让我们将剩余的卡牌合成超级稀有卡吧。
                        合成出来的卡牌可以挖出大量的 OVO代币。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content4">
                      <p>
                        No worries at all, haha! Let's fuse all the surplus
                        cards and go for super-rare cards. By doing that, we can
                        mine lots of $OVO.Although gutting the token pool... It
                        might cost me…
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content4">
                      <p>
                        そんなことないですよ（笑）　余ったにゃっちーずNFTを合成しまくって、超レアにゃっちーずNFTを目指しましょう。それをもって$OVOトークンを大量に掘りまくって、トークンのプールを空っぽにしてください！...って、僕が損するかも...
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        {languageType === 0 ? (
          <TitleContent
            stepContent={"步骤2"}
            introduceContent={"通过合成芝士猫卡牌，来制作超稀有卡牌吧！"}
            catImgUrl={greenCat}
          />
        ) : languageType === 1 ? (
          <TitleContent
            stepContent={"Step 2"}
            introduceContent={"Fuse surplus Nyathees to make super-rare cards!"}
            catImgUrl={greenCat}
          />
        ) : (
          <TitleContent
            stepContent={"ステップ 2"}
            introduceContent={
              "ガチャガチャで余ったにゃっちーずNFTを合成しながら超レアにゃっちーずNFTを作りまくろう！"
            }
            catImgUrl={greenCat}
          />
        )}

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content5">
                  <p>合成成功之后，好像额外掉落了一张$OVO卡？！　</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content5">
                  <p>Did the $OVO card drop on its own when I fuse it?　</p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content5">
                  <p>
                    5★合成に成功したら$OVOトークンNFTがドロップしたけど？！　
                    そもそも$OVOトークンNFTってなに？
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content6">
                      <p>
                        可以理解为挖矿时的电池。而且，你也可以用Energy来抽卡。尽量多积攒Energy吧。将来，如果开放交易对，它或许会更受欢迎哦...
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content6">
                      <p>
                        Try listing the $OVO cards  on the marketplace, I'll buy
                        them in an instant. But if you list them for more than
                        face value, I might pass, haha!
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content6">
                      <p>
                        貰った$OVOトークンNFTをマーケットプレイスに出品してみてください、僕が当日に買い取ってみせます。但し、出品するときには必ず$OVOトークンNFTで示した額面通りに販売してくださいね！　出品するときはOVOトークンを選択することを忘れないようにしてください～　
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content6">
                      <p>
                        可以理解为挖矿时的电池。而且，你也可以用Energy来抽卡。尽量多积攒Energy吧。将来，如果开放交易对，它或许会更受欢迎哦...
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content6">
                      <p>
                        Try listing the $OVO cards  on the marketplace, I'll buy
                        them in an instant. But if you list them for more than
                        face value, I might pass, haha!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content6">
                      <p>
                        貰った$OVOトークンNFTをマーケットプレイスに出品してみてください、僕が当日に買い取ってみせます。但し、出品するときには必ず$OVOトークンNFTで示した額面通りに販売してくださいね！　出品するときはOVOトークンを選択することを忘れないようにしてください～　
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? fusionMobile : fusion}
          className="content_img"
          alt="fusion"
        />
        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content7">
                  <p>合成后，好像拿到了叫Energy的代币？！  这是什么东西？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content7">
                  <p>
                    I got a World Domination Ticket in PictureBook! But what's
                    it for?
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content7">
                  <p>合成に成功したら、勝手にEnergyがドロップしたけど？！</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        可以理解为挖矿时的电池。而且，你也可以用Energy来抽卡。尽量多积攒Energy吧。将来，如果开放交易对，它或许会更受欢迎哦...
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        That's for batteries when mining. Also, you can use
                        Energy to draw cards. But try to accumulate as much
                        Energy as you can. In the future, it might be more
                        popular once it's publicly traded…
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        Energyは、Miningするときに使うバッテリーです。それに、Energyでガチャも回せますよ。出来るだけいっぱい貯めこみましょう。将来、Energyを公に取引できるようになると、ちょっとヤバくなる...かもしれませんよ...
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        可以理解为挖矿时的电池。而且，你也可以用Energy来抽卡。尽量多积攒Energy吧。将来，如果开放交易对，它或许会更受欢迎哦...
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        That's for batteries when mining. Also, you can use
                        Energy to draw cards. But try to accumulate as much
                        Energy as you can. In the future, it might be more
                        popular once it's publicly traded…
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content8">
                      <p>
                        Energyは、Miningするときに使うバッテリーです。それに、Energyでガチャも回せますよ。出来るだけいっぱい貯めこみましょう。将来、Energyを公に取引できるようになると、ちょっとヤバくなる...かもしれませんよ...
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        {languageType === 0 ? (
          <TitleContent
            stepContent={"步骤 3"}
            introduceContent={"打开PictureBook，集齐你的芝士猫卡牌！"}
            catImgUrl={yellowCat}
          />
        ) : languageType === 1 ? (
          <TitleContent
            stepContent={"Step 3"}
            introduceContent={
              "Open your PictureBook and collect your captured Nyathees cards!"
            }
            catImgUrl={yellowCat}
          />
        ) : (
          <TitleContent
            stepContent={"ステップ 3"}
            introduceContent={
              "PictureBookを開いて、捕獲したにゃっちーずを集めてみよう！"
            }
            catImgUrl={yellowCat}
          />
        )}

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content9">
                  <p>
                    我在PictureBook里获得了World Domination
                    Ticket世界征服券！它有什么用呢？
                  </p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content9">
                  <p>
                    I got a World Domination Ticket in PictureBook! But what's
                    it for?
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content9">
                  <p>
                    PictureBookで世界征服チケットWorld Domination
                    Ticketを貰った！　 けど、何ができる？
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        恭喜你！ 当USDT-BEP20的盲盒售罄时，将把总额为77777
                        USDT（约55万RMB）的奖金分配给世界征服券的持有者们。当然，持有得越多，分配得越多。
                        据我所知，目前持有这张卡的人似乎不多哦...如果被一个人独占就麻烦了。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        Congratulations! When the USDT-BEP20 Mystery boxes are
                        sold out, a total prize of 77,777 USDT will be
                        distributed among the holders of World Domination
                        Tickets. Of course, the more you hold, the more you get.
                        From what I know, there don't seem to be many people
                        holding this card at the moment... It could be
                        troublesome if one person ends up having it all.
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        おめでとうございます！　
                        USDT-BEP20型ガチャガチャが売り切れた際、総額77777USDT（約1000万以上）の賞金を世界征服チケットのホルダー達に分配します。勿論、たくさん持てば持つほど、多く分配されます。聞いたところ、そのカードを持ってる方は、今現在、あまりいないような気がしますが...独り占めにされたら困りますね
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        恭喜你！ 当USDT-BEP20的盲盒售罄时，将把总额为77777
                        USDT（约55万RMB）的奖金分配给世界征服券的持有者们。当然，持有得越多，分配得越多。
                        据我所知，目前持有这张卡的人似乎不多哦...如果被一个人独占就麻烦了。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        Congratulations! When the USDT-BEP20 Mystery boxes are
                        sold out, a total prize of 77,777 USDT will be
                        distributed among the holders of World Domination
                        Tickets. Of course, the more you hold, the more you get.
                        From what I know, there don't seem to be many people
                        holding this card at the moment... It could be
                        troublesome if one person ends up having it all.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content10">
                      <p>
                        おめでとうございます！　
                        USDT-BEP20型ガチャガチャが売り切れた際、総額77777USDT（約1000万以上）の賞金を世界征服チケットのホルダー達に分配します。勿論、たくさん持てば持つほど、多く分配されます。聞いたところ、そのカードを持ってる方は、今現在、あまりいないような気がしますが...独り占めにされたら困りますね
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? pictureBook2Mobile : pictureBook2}
          alt="pictureBook"
          className="content_img"
        />
        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content11">
                  <p>收集NyatheesPuzzle（拼图）有什么用呢？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content11">
                  <p>What is the use of collecting NyatheesPuzzle?</p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content11">
                  <p>NyatheesPuzzleって、集めてどうするんだろう？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        当你收集了所有的Puzzle（拼图）时，你将获得一个叫GoldPass的芝士猫头像。总共只有111个，这是一个设计巧妙的收藏品，其中包含各种特权。例如，在进行市场交易时，手续费会减半，挖矿效率会提高等等。总之，这是相当稀有的特权NFT。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        When you collect all the puzzle pieces, you'll receive a
                        Nyathee's avatar called the GoldPass. There are only a
                        total of 111 pieces, but it's a collection with various
                        privileges built in. For example, it can reduce
                        transaction fees by half when trading in the
                        marketplace, increase mining efficiency, and more. It's
                        quite a rare item, to say the least.
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        パズルピースを全部揃えると、GoldPassと言われるにゃっちーずアバターNFTを貰えます。合計111枚しかありませんが、色んな特権が仕組まれています。例えば、マーケットプレイスで取引するときに、手数料が半減するとか、マイニングの効率が上がるとか、取りあえずかなりレアな代物ですよ。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        当你收集了所有的Puzzle（拼图）时，你将获得一个叫GoldPass的芝士猫头像。总共只有111个，这是一个设计巧妙的收藏品，其中包含各种特权。例如，在进行市场交易时，手续费会减半，挖矿效率会提高等等。总之，这是相当稀有的特权NFT。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        When you collect all the puzzle pieces, you'll receive a
                        Nyathee's avatar called the GoldPass. There are only a
                        total of 111 pieces, but it's a collection with various
                        privileges built in. For example, it can reduce
                        transaction fees by half when trading in the
                        marketplace, increase mining efficiency, and more. It's
                        quite a rare item, to say the least.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content12">
                      <p>
                        パズルピースを全部揃えると、GoldPassと言われるにゃっちーずアバターNFTを貰えます。合計111枚しかありませんが、色んな特権が仕組まれています。例えば、マーケットプレイスで取引するときに、手数料が半減するとか、マイニングの効率が上がるとか、取りあえずかなりレアな代物ですよ。
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? nyatheesPuzzleMobile : nyatheesPuzzle}
          alt="nyathees puzzle"
          className="content_img"
        />
        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content13">
                  <p>我有很多相同的卡片，在PictureBook中还有其他用途吗？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content13">
                  <p>
                    I have a lot of the same cards left, but what else can I do
                    in PictureBook?
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content13">
                  <p>
                    同じNFTがいっぱい余ってるんだけど、ほかにPictureBookで何ができる？
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        在PictureBook中选择你想征服的路线，然后有针对性地收集NFT卡牌。如果运气好的话，你会获得6★卡牌，或者你也可以尝试去追踪“神秘”卡牌，也许会有超级惊喜等待你发现（笑）。选择好你的线路朝着目标努力集齐吧！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        Try selecting a route you want to conquer in PictureBook
                        and collect cards specifically for that route. If you're
                        lucky, you might acquire 6★ cards or aim for mysterious
                        "bonus" cards, which could potentially have some unknown
                        utility in the future, not bad at all, haha. Feel free
                        to try various things!
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        PictureBookで攻略したいルートを選択してピンポイントでNFTを集めてみてください。運が良ければ、6★NFTとかを獲得できます。若しくは、謎の「おまけ」NFTを狙って、又もや謎のユーティリティを待機しても悪くないかもしれませんね（笑）。いろいろと試してみてください！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        在PictureBook中选择你想征服的路线，然后有针对性地收集NFT卡牌。如果运气好的话，你会获得6★卡牌，或者你也可以尝试去追踪“神秘”卡牌，也许会有超级惊喜等待你发现（笑）。选择好你的线路朝着目标努力集齐吧！
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        Try selecting a route you want to conquer in PictureBook
                        and collect cards specifically for that route. If you're
                        lucky, you might acquire 6★ cards or aim for mysterious
                        "bonus" cards, which could potentially have some unknown
                        utility in the future, not bad at all, haha. Feel free
                        to try various things!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content14">
                      <p>
                        PictureBookで攻略したいルートを選択してピンポイントでNFTを集めてみてください。運が良ければ、6★NFTとかを獲得できます。若しくは、謎の「おまけ」NFTを狙って、又もや謎のユーティリティを待機しても悪くないかもしれませんね（笑）。いろいろと試してみてください！
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? pictureBookMobile : pictureBook}
          alt="pictureBook2"
          className="content_img"
        />
        {languageType === 0 ? (
          <TitleContent
            stepContent={"步骤4"}
            introduceContent={`打开Mining页面，装备上芝士猫卡牌来挖掘$OVO！`}
            catImgUrl={purpleCat}
          />
        ) : languageType === 1 ? (
          <TitleContent
            stepContent={"Step 4"}
            introduceContent={`Open the Mining page and equip Nyathees cards to mine $OVO!`}
            catImgUrl={purpleCat}
          />
        ) : (
          <TitleContent
            stepContent={"ステップ 4"}
            introduceContent={`Miningページを開き、にゃっちーずNFTをセットして$OVOトークンを掘りまくろう！`}
            catImgUrl={purpleCat}
          />
        )}

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content15">
                  <p>用NFT挖矿是指什么？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content15">
                  <p>What does NFT mining mean? </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content15">
                  <p>NFTでマイニングってナニ？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        简单来说，NFT挖矿是指将芝士猫卡牌嵌入其中，然后坐等$OVO增多。不过，只有3★到6★的卡牌才能挖掘出$OVO，1★和2★的卡片可以视为游戏中的增益和减益卡牌（Buff）。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        Simply put, NFT mining involves embedding Nyathees cards
                        and waiting for the accumulation of $OVO. However, only
                        cards rated 3★ to 6★ can mine $OVO, while 1★ and 2★
                        cards can be considered as game-related enhancement and
                        debuff or buff cards.
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        簡単に説明すると、にゃっちーずNFTをセットして、$OVOトークンが貯まるまで待つ行為を指してますね。但し、$OVOトークンを掘り出せるにゃっちーずNFTは、3★から6★が付いているNFTではないとダメですね。1★と2★NFTはゲームで言うと、いわばバフとデバフNFTです。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        简单来说，NFT挖矿是指将芝士猫卡牌嵌入其中，然后坐等$OVO增多。不过，只有3★到6★的卡牌才能挖掘出$OVO，1★和2★的卡片可以视为游戏中的增益和减益卡牌（Buff）。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        Simply put, NFT mining involves embedding Nyathees cards
                        and waiting for the accumulation of $OVO. However, only
                        cards rated 3★ to 6★ can mine $OVO, while 1★ and 2★
                        cards can be considered as game-related enhancement and
                        debuff or buff cards.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content16">
                      <p>
                        簡単に説明すると、にゃっちーずNFTをセットして、$OVOトークンが貯まるまで待つ行為を指してますね。但し、$OVOトークンを掘り出せるにゃっちーずNFTは、3★から6★が付いているNFTではないとダメですね。1★と2★NFTはゲームで言うと、いわばバフとデバフNFTです。
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? miningMobile : mining}
          className="content_img"
          alt="mining"
        />

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content17">
                  <p>挖矿页面有6个卡槽，有什么不同吗？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content17">
                  <p>
                    Are there any differences between the 6 card slots on the
                    mining page? 
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content17">
                  <p>マイニングページに枠が6つあるけど...どう違うの？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        非常简单！将3★到6★卡牌放入左侧的4个卡槽，你就可以挖出$OVO代币。将1★和2★卡牌放入右侧的2个卡槽，就可以激活增益或减益效果！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        It's quite straightforward! Place 3★ to 6★ cards into
                        the left four slots to accumulate $OVO. Place 1★ and 2★
                        cards into the right two slots to activate buffs or
                        debuffs!
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        超簡単です！
                        左側にある4つのスロット（枠）に3★から6★NFTをセットすると$OVOトークンが貯まります。また、右側にある2つのスロット（枠）に1★、2★NFTをセットすると、バフかデバフを発動できますよ！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        非常简单！将3★到6★卡牌放入左侧的4个卡槽，你就可以挖出$OVO代币。将1★和2★卡牌放入右侧的2个卡槽，就可以激活增益或减益效果！
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        It's quite straightforward! Place 3★ to 6★ cards into
                        the left four slots to accumulate $OVO. Place 1★ and 2★
                        cards into the right two slots to activate buffs or
                        debuffs!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content18">
                      <p>
                        超簡単です！
                        左側にある4つのスロット（枠）に3★から6★NFTをセットすると$OVOトークンが貯まります。また、右側にある2つのスロット（枠）に1★、2★NFTをセットすると、バフかデバフを発動できますよ！
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? mining2Mobile : mining2}
          className="content_img"
          alt="mining2"
        />

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content19">
                  <p>哦~我明白了。 但还是不太清楚什么是增益和减益？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content19">
                  <p>I see. But what are buffs and debuffs?</p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content19">
                  <p>なるほど。そもそも、バフとデバフとは？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        带有 BoostRate 属性的卡牌有增益效果。
                        这意味着使用这张卡片可以获得更多的$OVO。带有 BombRate
                        属性的卡牌有减益效果，如果你把它们扔到正在挖矿的人的钱包地址上，就会减少他们获得的
                        $OVO 数量... 这些卡通常被称为炸弹卡。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        Cards with the BoostRate attribute have a buff effect.
                        This means using these cards can earn you more $OVO.
                        Cards with the BombRate attribute are debuff cards. If
                        you throw them onto the wallet address of an opponent
                        who is mining, it reduces the amount of $OVO they
                        earn... These cards are often referred to as "bomb
                        cards." Be sure not to leak any  wallet address
                        information…
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        BoostRateという属性があるNFTはバフ効果がついていますね。つまり、このNFTを使うとより多くの$OVOトークンを貰える訳です。
                        BombRateという属性がついているNFTは、いわばデバフNFTですが、マイニングしている相手のウォレットアドレスに投げつけると、向こう側が貰える$OVOトークンの量を減らすことになります...こういうNFTを俗に爆弾と称します。
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        带有 BoostRate 属性的卡牌有增益效果。
                        这意味着使用这张卡片可以获得更多的$OVO。带有 BombRate
                        属性的卡牌有减益效果，如果你把它们扔到正在挖矿的人的钱包地址上，就会减少他们获得的
                        $OVO 数量... 这些卡通常被称为炸弹卡。
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        Cards with the BoostRate attribute have a buff effect.
                        This means using these cards can earn you more $OVO.
                        Cards with the BombRate attribute are debuff cards. If
                        you throw them onto the wallet address of an opponent
                        who is mining, it reduces the amount of $OVO they
                        earn... These cards are often referred to as "bomb
                        cards." Be sure not to leak any  wallet address
                        information…
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content20">
                      <p>
                        BoostRateという属性があるNFTはバフ効果がついていますね。つまり、このNFTを使うとより多くの$OVOトークンを貰える訳です。
                        BombRateという属性がついているNFTは、いわばデバフNFTですが、マイニングしている相手のウォレットアドレスに投げつけると、向こう側が貰える$OVOトークンの量を減らすことになります...こういうNFTを俗に爆弾と称します。
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? mining3Mobile : mining3}
          className="content_img"
          alt="mining3"
        />

        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content21">
                  <p>左侧的挖矿槽的电池耗尽了，怎么办呢？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content21">
                  <p>
                    It seems that the batteries in the mining slots on the left
                    are depleted, what's going on?!
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content21">
                  <p>
                    どうやら、左側にあるマイニングスロット（枠）
                    のバッテリーが切れてるんだけど、どういうこと？！！
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        挖矿槽的电池耗尽时，你可以用Energy为其充电，使其重新运行。但每次充电后，电池性能会有所下降，导致电池最大容量减少。当最大容量降至零时，卡槽将被锁上并无法使用，此时可以使用$OVO来解锁，卡槽将变成全新的！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        The slots have batteries, and when they're used up, you
                        can recharge them with Energy to make them operational
                        again. However, please be aware that each time you
                        recharge, the battery degrades, and its maximum capacity
                        decreases. When the maximum capacity reaches zero, the
                        slots will be locked and unusable. But at that time, you
                        can use $OVO to unlock them again, and the slots will be
                        refreshed!
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        スロット（枠）にはバッテリーがついてますが、使い切ったらEnergyでリチャージすると再び稼働します。但し、リチャージする度にバッテリーが劣化して最大容量が減少しますので、ご注意ください。最大容量がゼロになると、スロット（枠）にロックが掛かって使えなくなりますが、その時には$OVOトークンを使って再びロック解除すると、スロット（枠）が新しくなります！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        挖矿槽的电池耗尽时，你可以用Energy为其充电，使其重新运行。但每次充电后，电池性能会有所下降，导致电池最大容量减少。当最大容量降至零时，卡槽将被锁上并无法使用，此时可以使用$OVO来解锁，卡槽将变成全新的！
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        The slots have batteries, and when they're used up, you
                        can recharge them with Energy to make them operational
                        again. However, please be aware that each time you
                        recharge, the battery degrades, and its maximum capacity
                        decreases. When the maximum capacity reaches zero, the
                        slots will be locked and unusable. But at that time, you
                        can use $OVO to unlock them again, and the slots will be
                        refreshed!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content22">
                      <p>
                        スロット（枠）にはバッテリーがついてますが、使い切ったらEnergyでリチャージすると再び稼働します。但し、リチャージする度にバッテリーが劣化して最大容量が減少しますので、ご注意ください。最大容量がゼロになると、スロット（枠）にロックが掛かって使えなくなりますが、その時には$OVOトークンを使って再びロック解除すると、スロット（枠）が新しくなります！
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
        <img
          src={isMobile ? mining4Mobile : mining4}
          className="content_img"
          alt="mining4"
        />
        <div className="main_dialog_container_left">
          <img
            className="user_avatar"
            src={isMobile ? userAvatarMobile : userAvatar}
            alt="user avatar"
          />
          <div className="main_dialog_content_container_left">
            {languageType === 0 ? (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content23">
                  <p>如何获取Energy？</p>
                </div>
              </>
            ) : languageType === 1 ? (
              <>
                <p>U</p>
                <div className="main_dialog_content_base main_dialog_content23">
                  <p>EHow do I get Energy?</p>
                </div>
              </>
            ) : (
              <>
                <p>U君</p>
                <div className="main_dialog_content_base main_dialog_content23">
                  <p>Energyを入手する方法は？</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="main_dialog_container_right">
          <div className="main_dialog_content_container_right">
            {isMobile ? (
              <>
                {languageType === 0 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        Energy的获取方法在白皮书中有详细说明。简单来说，你可以通过多多参与交互获得Energy，例如抽取盲盒、合成、PictureBook、二级市场出售等方式，。另外，在Discord社区积极参与也可以获得Energy哦！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        The method of obtaining Energy is explained in detail in
                        the whitepaper. In a nutshell, you can earn Energy by
                        using Fusion,Mystery Box, PictureBook, marketplace, and
                        more. Additionally, active participation in the Discord
                        community can also earn you Energy!
                      </p>
                    </div>
                    <p>OVO</p>
                  </>
                ) : (
                  <>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        Energyの入手方法は、ホワイトペーパーに詳しく掲載しています。簡単に紹介しますと、OVO
                        SPACEのホームでガチャガチャ、合成、PictureBook、マーケットプレイス等を頻繁に使用するとEnergyを貰えます。ほかに、Discordコミュニティで活躍しても、同じくEnergyを貰うことができますよ！
                      </p>
                    </div>
                    <p>OVO君</p>
                  </>
                )}
              </>
            ) : (
              <>
                {languageType === 0 ? (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        Energy的获取方法在白皮书中有详细说明。简单来说，你可以通过多多参与交互获得Energy，例如抽取盲盒、合成、PictureBook、二级市场出售等方式，。另外，在Discord社区积极参与也可以获得Energy哦！
                      </p>
                    </div>
                  </>
                ) : languageType === 1 ? (
                  <>
                    <p>OVO</p>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        The method of obtaining Energy is explained in detail in
                        the whitepaper. In a nutshell, you can earn Energy by
                        using Fusion,Mystery Box, PictureBook, marketplace, and
                        more. Additionally, active participation in the Discord
                        community can also earn you Energy!
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <p>OVO君</p>
                    <div className="main_dialog_content_base main_dialog_content24">
                      <p>
                        Energyの入手方法は、ホワイトペーパーに詳しく掲載しています。簡単に紹介しますと、OVO
                        SPACEのホームでガチャガチャ、合成、PictureBook、マーケットプレイス等を頻繁に使用するとEnergyを貰えます。ほかに、Discordコミュニティで活躍しても、同じくEnergyを貰うことができますよ！
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <img
            className="ovo_avatar"
            src={isMobile ? ovoAvatarMobile : ovoAvatar}
            alt="ovo avatar"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainContent);
