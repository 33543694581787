import React from "react";
import introduceDownArrow from "../assets/images/introduceDownArrows.png";
import introduceDownArrowMobile from "../assets/images/introduceDownArrowsMobile.png";
import introduceLeftCat from "../assets/images/introduceLeftCat.png";
import introduceRightCat from "../assets/images/introduceRightCat.png";
import introduceLeftCatMobile from "../assets/images/introduceLeftCatMobile.png";
import introduceRightCatMobile from "../assets/images/introduceRightCatMobile.png";
import { isMobile } from "react-device-detect";

const Introduce = ({ languageType }) => {
  return (
    <div className="introduce_container">
      <div className="introduce_dialog_container">
        <div className="introduce_dialog1">
          <img
            src={isMobile ? introduceLeftCatMobile : introduceLeftCat}
            alt="left cat"
          />
          <div className="introduce_left_dialog">
            {languageType === 0 ? (
              <p>芝士猫?！</p>
            ) : languageType === 1 ? (
              <p>Nyathees ? !</p>
            ) : (
              <p>にゃっちーず？！</p>
            )}
          </div>
        </div>
        <div className="introduce_dialog2">
          <div className="introduce_right_dialog">
            {languageType === 0 ? (
              <p>
                芝士猫真的是猫吗？都市传说中，他们其实是来地球暗杀 Hello Kitty
                的 UMA...
              </p>
            ) : languageType === 1 ? (
              <p>
                Are the  Nyathees really cats? Urban legend has it that they
                actually came to Earth to assassinate Hello Kitty's UMA...
              </p>
            ) : (
              <p>
                にゃっちーずは、ほんとにネコなの？　実はハローキティを暗殺するために地球へやってきたUMAという都市伝説が...
              </p>
            )}
          </div>
          <img
            src={isMobile ? introduceRightCatMobile : introduceRightCat}
            alt="right cat"
          />
        </div>
        {isMobile ? null : (
          <div className="introduce_dialog3">
            {languageType === 0 ? (
              <p>
                芝士猫（NFT）是在BSC链上发布的一组收藏卡牌，最初是由kawaBekon创作的极受欢迎的LINE表情包。于2021年，OVO.SPACE的技术支持下，在NFT艺术界中一跃成名，如今已成为大众熟知的热门NFT藏品！
              </p>
            ) : languageType === 1 ? (
              <p>
                Nyathees(NFT) is a collection of cards issued on the BSC Chain,
                originally created by Mr. Kawabekon as a wildly popular LINE
                stamp. In 2021, with technical support from OVO.SPACE, it has
                become widely known in the NFT art world and has become a
                popular NFT content!
              </p>
            ) : (
              <p>
                にゃっちーず（NFT）はBSCチェーンで発行されたコレクションカード群であり、元々はかわベーコン氏が創作した超人気LINEスタンプでした。そして、2021年からOVO.SPACEの技術的サポートのもとでNFTアート界隈で一躍有名となり、人気NFTコンテンツとして世に知れ渡ることになりました！
              </p>
            )}
          </div>
        )}
      </div>
      {isMobile ? (
        <>
          <img
            src={introduceDownArrowMobile}
            alt="down arrow"
            className="introduce_down_arrow"
          />
          <div className="introduce_button">
            {languageType === 0 ? (
              <p>虽然我对它不太了解，但我需要做什么呢？</p>
            ) : languageType === 1 ? (
              <p>I don't know much about it, but what should I do for now!?</p>
            ) : (
              <p>
                あまり詳しくないけど、とりあえず、何をどうすればいんだろう！？
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="introduce_button">
            {languageType === 0 ? (
              <p>虽然我对它不太了解，但我需要做什么呢？</p>
            ) : languageType === 1 ? (
              <p>I don't know much about it, but what should I do for now!?</p>
            ) : (
              <p>
                あまり詳しくないけど、とりあえず、何をどうすればいんだろう！？
              </p>
            )}
          </div>
          <img
            src={introduceDownArrow}
            alt="down arrow"
            className="introduce_down_arrow"
          />
        </>
      )}
    </div>
  );
};

export default React.memo(Introduce);
