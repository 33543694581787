import React from "react";
import bannerDialogContent from "../assets/images/bannerDialogContent.png";
import bannerDialogContentMobile from "../assets/images/bannerDialogContentMobile.png";
import bannerDialogContentEN from "../assets/images/bannerDialogContentEN.png";
import bannerDialogContentZH from "../assets/images/bannerDialogContentZH.png";
import bannerDialogContentENMobile from "../assets/images/bannerDialogContentENMobile.png";
import bannerDialogContentZHMobile from "../assets/images/bannerDialogContentZHMobile.png";
import bannerBottomCats from "../assets/images/bannerBottomCats.png";
import bannerBottomCat from "../assets/images/bannerBottomCat.png";
import bannerBottomCatsMobile from "../assets/images/bannerBottomCatsMobile.png";
import bannerBottomCatMobile from "../assets/images/bannerBottomCatMobile.png";
import { isMobile } from "react-device-detect";

const Banner = ({ languageType }) => {
  return (
    <div className="banner_container">
      <div className="banner_green_gap"></div>
      <div className="banner_bg">
        <div className="banner_dialog">
          {languageType === 0 ? (
            <img
              src={
                isMobile ? bannerDialogContentZHMobile : bannerDialogContentZH
              }
              alt="dialog"
            ></img>
          ) : languageType === 1 ? (
            <img
              src={
                isMobile ? bannerDialogContentENMobile : bannerDialogContentEN
              }
              alt="dialog"
            ></img>
          ) : (
            <img
              src={isMobile ? bannerDialogContentMobile : bannerDialogContent}
              alt="dialog"
            ></img>
          )}

          <img
            className="banner_bottom_cat"
            src={isMobile ? bannerBottomCatMobile : bannerBottomCat}
            alt="bottom cat"
          ></img>
        </div>
        <img
          className="banner_bottom_cats"
          src={isMobile ? bannerBottomCatsMobile : bannerBottomCats}
          alt="bottom cats"
        ></img>
      </div>
      <div className="banner_green_gap"></div>
    </div>
  );
};

export default React.memo(Banner);
